import React from "react";
import { default as fbClient } from "firebase";

// if (!fbClient.apps.length) {
//   fbClient.initializeApp({
//     apiKey: "AIzaSyCNsRQ5wNpr6ZWIeS_8EWyWZpKf8MPxWVI",
//     authDomain: "lets-meal-prod.firebaseapp.com",
//     databaseURL: "https://lets-meal-prod.firebaseio.com",
//     projectId: "lets-meal-prod",
//     storageBucket: "lets-meal-prod.appspot.com",
//     messagingSenderId: "848406507659",
//     appId: "1:848406507659:web:ee379b04f413f44528648e",
//   });
// }

if (!fbClient.apps.length) {
  fbClient.initializeApp({
    apiKey: "AIzaSyDyy1T-CAUihCGPcp4aQ_5HNy4uimy9its",
    authDomain: "lets-meal-prd.firebaseapp.com",
    projectId: "lets-meal-prd",
    storageBucket: "lets-meal-prd.appspot.com",
    messagingSenderId: "1008585678900",
    appId: "1:1008585678900:web:e939cd0a99912639e4aa61",
    measurementId: "G-VQ4YD6Z7W6"
  });
}

export const firebaseClient = fbClient;
